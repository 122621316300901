import React, { Component } from "react"
import { withFirebase } from "./Firebase"
import { navigate } from "gatsby"
import * as ROUTES from "../constants/routes"
import Export from "./export"
import ReactToPrint from "react-to-print"

function getAverage(nums) {
  return nums.reduce((a, b) => a + b) / nums.length
}

class Download extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overalScore: 0,
      submission: [
        {
          title: "Rationale",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Proposition",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Markets",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Business Model",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
        {
          title: "Partner Strategy",
          questions: [
            {
              question: "Question 01",
            },
            {
              question: "Question 02",
            },
            {
              question: "Question 03",
            },
            {
              question: "Question 04",
            },
          ],
        },
      ],
      chart: [
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
        {
          section: "",
          result: 0,
        },
      ],
    }
  }

  componentDidMount() {
    const { user } = this.props
    const { submission } = user
    let chartResults = []
    let overalScore = 0

    for (let i in submission) {
      let answers = []
      let questions = submission[i].questions

      for (let e in questions) {
        answers.push(questions[e].answer)
      }

      let averageResult = {
        section: submission[i].title,
        result: getAverage(answers),
      }
      overalScore += getAverage(answers)
      chartResults.push(averageResult)
    }

    this.setState({
      chart: chartResults,
      results: submission,
      overalScore: overalScore,
      loaded: true,
    })

    setTimeout(() => {
      this.setState({
        chart_load: true,
      })
    }, 1000)
  }

  render() {
    const authUser = this.props.user

    if (!authUser.completed) navigate(ROUTES.QUESTIONNAIRE)

    return (
      <>
        <section className="page_intro">
          <div className="wrapper">
            <div className="">
              <h1 className="heading--xl thin">Full Results</h1>
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <button className="btn red">
                      <span>Print report</span>
                    </button>
                  )
                }}
                content={() => this.componentRef}
              />
            </div>
          </div>
        </section>
        <section
          className="section_download"
          id="export-pdf"
          ref={el => (this.componentRef = el)}
        >
          <div className="wrapper">
            <h2 className="heading--l">Results Summary</h2>
            <br />
            <div className="export">
              {this.state.loaded ? (
                <>
                  <ul className="chart_mobile">
                    {this.state.chart.map((result, result_id) => {
                      let percent = result.result.toFixed(0)
                      return (
                        <li key={result_id}>
                          <p>{result.section}</p>
                          <span>{percent}</span>
                        </li>
                      )
                    })}
                  </ul>

                  <div className="chart_container">
                    <div className="chart_labels">
                      {this.state.chart.map((result, result_id) => {
                        return (
                          <div className="chart_label" key={result_id}>
                            <p>{result.section}</p>
                          </div>
                        )
                      })}
                    </div>

                    <div className="chart">
                      <div className="chart_splits">
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                        <div className="chart_split"></div>
                      </div>
                      <span className="chart_line chart_line-0">
                        <p>0</p>
                      </span>
                      <span className="chart_line chart_line-1">
                        <p>1</p>
                      </span>
                      <span className="chart_line chart_line-2">
                        <p>2</p>
                      </span>
                      <span className="chart_line chart_line-3">
                        <p>3</p>
                      </span>
                      <span className="chart_line chart_line-4">
                        <p>4</p>
                      </span>
                      <span className="chart_line chart_line-5">
                        <p>5</p>
                      </span>
                      <span className="chart_line chart_line-6">
                        <p>6</p>
                      </span>
                      <span className="chart_line chart_line-7">
                        <p>7</p>
                      </span>
                      <span className="chart_line chart_line-8">
                        <p>8</p>
                      </span>
                      <span className="chart_line chart_line-9">
                        <p>9</p>
                      </span>
                      <span className="chart_line chart_line-10">
                        <p>10</p>
                      </span>
                      {this.state.chart.map((result, result_id) => {
                        let percent = 0

                        if (this.state.chart_load) {
                          percent = result.result.toFixed(0) * 10
                        }

                        return (
                          <div className="chart_bar" key={result_id}>
                            <div
                              className={
                                percent > 95
                                  ? "chart_bar_inner full"
                                  : "chart_bar_inner"
                              }
                            >
                              <div style={{ width: percent + "%" }}></div>
                              <span
                                className={
                                  this.state.chart_load ? "loaded" : ""
                                }
                              >
                                {result.result.toFixed(0)}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="form_results">
                    <h2 className="heading--l">Answers you submitted</h2>
                    <ul>
                      {this.state.results.map((submission, key) => {
                        let number = key + 1

                        return (
                          <li key={key}>
                            <h3 className="heading--m bold uppercase">
                              {number + ". " + submission.title}
                            </h3>
                            <br />
                            {submission.questions.map((answer, answer_id) => {
                              return (
                                <div key={answer_id}>
                                  <p className="body--m">{answer.question}</p>
                                  <p className="body--m answer">
                                    {answer.answer}
                                  </p>
                                </div>
                              )
                            })}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              ) : (
                <div className="results_loading">
                  <h2 className="heading--l">Loading your results</h2>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default withFirebase(Download)
