import React from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import Download from "../components/download"
import { AuthUserContext } from "../components/Session"
import { withAuthorization } from "../components/Session"

const condition = authUser => !!authUser

const DownloadPageBase = () => (
  <AuthUserContext.Consumer>
    {authUser => {
      return <Download user={authUser} />
    }}
  </AuthUserContext.Consumer>
)

const DownloadPage = compose(withAuthorization(condition))(DownloadPageBase)

export default () => (
  <Layout activePage="download">
    <DownloadPage />
  </Layout>
)
